<template>
  <div class="flex flex-grow justify-center items-center flex-col">
    <div class="max-w-400 w-full mx-auto">
      <h2 class="text-4xl font-medium text-black-900 mb-8">
        Enter new password
      </h2>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="space-y-6 mb-11"
      >
        <utils-input
          :validationRules="'required'"
          :inputName="'New password'"
          :inputType="'password'"
          :isSubmitted="isSubmitted"
        />

        <utils-input
          :validationRules="'required'"
          :inputName="'Repeat password'"
          :inputType="'password'"
          :isSubmitted="isSubmitted"
        />

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm">Change password</span>
        </base-button>
      </validation-observer>
      <p class="text-black-900 text-sm">
        Don't have an account yet?
        <router-link to="/sign-in" class="text-orange-900 hover:text-grey-700">
          Sign up
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";

export default {
  name: "AuthNewPasswordForm",
  components: {
    "utils-input": UtilsInput,
    "base-button": BaseButton,
    ValidationObserver,
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then((valid) => {
        if (!valid) {
          return;
        }
      });
    },
  },
};
</script>
