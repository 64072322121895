<template>
  <div class="bg-white-900 h-full">
    <div class="px-0 mx-auto relative h-full flex">
      <base-logo class="absolute top-4 left-8" />
      <auth-sign-in-form />
      <auth-new-password-form />
    </div>
  </div>
</template>

<script>
import BaseLogo from "@/components/base/BaseLogo.vue";
import AuthNewPasswordForm from "@/components/auth/AuthNewPasswordForm.vue";

export default {
  components: {
    "base-logo": BaseLogo,
    "auth-new-password-form": AuthNewPasswordForm,
  },
};
</script>
